<template>
  <div class="contact">
    <head-nav></head-nav>
    <div class="contact-banner">
      <img
        v-for="(item, index) in contactBanner"
        :key="index"
        :src="item.imgs"
        alt=""
      >
    </div>

    <div class="contact-wrap">
      <div class="contact-list">

        <div class="contact-list-imgs">
          <div class="contact-list-img">

            <div class="contact-list-img-left">
              <img
                src="@/assets/image/contact/contact-1.png"
                alt=""
              >
            </div>
            <div class="contact-list-img-right">
              <h3 class="contact-list-img-right-info">{{$t('phone')}}</h3>
              <div class="contact-list-img-right-content">
                {{contactList[0].phone}}
              </div>
            </div>
          </div>
          <div class="contact-list-img">

            <div class="contact-list-img-left">
              <img
                src="@/assets/image/contact/contact-2.png"
                alt=""
              >
            </div>
            <div class="contact-list-img-right">
              <h3 class="contact-list-img-right-info">{{$t('fax')}}</h3>
              <div class="contact-list-img-right-content">
                {{contactList[0].fax}}
              </div>
            </div>
          </div>
          <div class="contact-list-img">

            <div class="contact-list-img-left">
              <img
                src="@/assets/image/contact/contact-3.png"
                alt=""
              >
            </div>
            <div class="contact-list-img-right">
              <h3 class="contact-list-img-right-info">{{$t('email')}}</h3>
              <div class="contact-list-img-right-content">
                Joyceyy88@163.com
              </div>
            </div>
          </div>

          <div class="contact-list-content">
            <div>
              {{contactList[0].content}}
            </div>
            <div>
              <img src="@/assets/image/contact/map.png" width="100%" alt="">
            </div>
          </div>

        </div>
      </div>

      <div class="contact-form">
        <h3 class="contact-form-title">
          {{$t('contact.title-2')}}
        </h3>
        <form class="input-message">
          <div class="input-box">
            <div class="input-list">
              <label>
                <span>{{$t('contact.name.label')}}</span>
                <input
                  type="text"
                  name="user"
                  :placeholder="$t('contact.name.placeholder')"
                  v-model="name"
                >
              </label>
            </div>

            <div class="input-list">
              <label>
                <span>{{$t('contact.email.label')}}</span>
                <input
                  type="text"
                  name="email"
                  :placeholder="$t('contact.email.placeholder')"
                  v-model="email"
                >
              </label>
            </div>
            <div class="input-list">
              <label>
                <span>{{$t('contact.telephone.label')}} </span>
                <input
                  type="text"
                  name="phone"
                  :placeholder="$t('contact.telephone.placeholder')"
                  v-model="phone"
                >
              </label>
            </div>
            <div class="input-list">
              <label>
                <span>{{$t('contact.company.label')}} </span>
                <input
                  type="text"
                  name="company"
                  :placeholder="$t('contact.company.placeholder')"
                  v-model="company"
                >
              </label>
            </div>
            <div class="input-list">
              <label>
                <span>{{$t('contact.comment.label')}}</span>
                <textarea
                  :placeholder="$t('contact.comment.placeholder')"
                  v-model="content"
                ></textarea>
              </label>
            </div>
          </div>
        </form>
        <div class="message-btn">
          <button @click="addFeedback">{{$t('contact.submit')}}</button>
        </div>
      </div>

    </div>
    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { required, email, numeric } from 'vuelidate/lib/validators';
import banner from '@/assets/image/contact/banner.png';
import HeadNav from './head-nav';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    Footers,
  },
  apollo: {
    contactList: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              contactList: allIntroduceMyselves {
                phone
                fax
                content
              }
            }
          `;
        }
        return gql`
          query {
            contactList: allEnIntroduceMyselves {
              phone
              fax
              content
            }
          }
        `;
      },
    },
  },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      company: '',
      content: '',
      contactList: [
        {
          phone: '',
        },
      ],
      contactBanner: [
        {
          id: 1,
          imgs: banner,
        },
      ],
    };
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
      numeric,
    },
    company: {
      required,
    },
    content: {
      required,
    },
  },

  methods: {
    async addFeedback() {
      if (this.$i18n.locale === 'cn') {
        try {
          this.$v.$touch();
          if (this.$v.$invalid) {
            this.$notify({
              type: 'error',
              title: '请完善信息',
            });
            return;
          }
          await this.$apollo.mutate({
            // 查询语句
            mutation: gql`
              mutation(
                $name: String!
                $email: String!
                $phone: String!
                $company: String!
                $content: String!
              ) {
                createFeedback(
                  data: {
                    name: $name
                    email: $email
                    phone: $phone
                    company: $company
                    content: $content
                  }
                ) {
                  id
                }
              }
            `,
            // 参数
            variables: {
              name: this.name,
              email: this.email,
              phone: this.phone,
              company: this.company,
              content: this.content,
            },
          });
          this.$notify({
            type: 'success',
            title: '提交成功',
          });
          Object.assign(this, {
            name: '',
            email: '',
            phone: '',
            company: '',
            content: '',
          });
        } catch (error) {
          this.$notify({
            type: 'error',
            title: '提交失败',
          });
        }
      } else if (this.$i18n.locale === 'en') {
        try {
          this.$v.$touch();
          if (this.$v.$invalid) {
            this.$notify({
              type: 'error',
              title: 'Please complete the information',
            });
            return;
          }
          await this.$apollo.mutate({
            // 查询语句
            mutation: gql`
              mutation(
                $name: String!
                $email: String!
                $phone: String!
                $company: String!
                $content: String!
              ) {
                createFeedback(
                  data: {
                    name: $name
                    email: $email
                    phone: $phone
                    company: $company
                    content: $content
                  }
                ) {
                  id
                }
              }
            `,
            // 参数
            variables: {
              name: this.name,
              email: this.email,
              phone: this.phone,
              company: this.company,
              content: this.content,
            },
          });
          this.$notify({
            type: 'success',
            title: 'success',
          });
          Object.assign(this, {
            name: '',
            email: '',
            phone: '',
            company: '',
            content: '',
          });
        } catch (error) {
          this.$notify({
            type: 'error',
            title: 'error',
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  &-banner {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .contact-wrap {
    padding: 40px 30px 100px 30px;
    background: #f8f8f8;

    .contact-list {
      &-title {
        margin: 50px 0 30px 0;
        text-align: center;
        width: 100%;

        img {
          width: 230px;
          height: 70px;
        }
      }

      &-content {
        line-height: 40px;
        font-size: 28px;
        color: #646464;
      }

      &-imgs {
        margin-top: 30px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        background: #fff;
        border: 1px solid #eee;

        .contact-list-img {
          margin: 0 18px;
          height: 200px;
          background: #fff;
          display: flex;
          flex: 1;
          justify-content: space-around;
          border-bottom: 1px solid #eee;

          &-left {
            width: 220px;

            img {
              width: 100px;
              height: 100px;
              margin: 50px 0 0 50px;
            }
          }

          &-right {
            width: 600px;
            padding: 50px 0;

            &-info {
              width: 100%;
              font-size: 36px;
              font-weight: 500;
              color: #333333;
              margin-bottom: 20px;
              margin-left: 4px;
              color: #333;
            }

            &-content {
              font-size: 48px;
            }
          }
        }

        .contact-list-content {
          background: #fff;
          padding: 50px 20px 50px 20px;
        }
      }
    }

    .contact-form {
      margin: 20px 0 100px 0;
      padding: 40px 0 70px 0;
      background: #fff;
      border-radius: 10px;
      border: 1px solid #eee;

      &-title {
        text-align: center;
        font-size: 36px;
        font-weight: 100;
        margin-bottom: 30px;
      }

      .input-box {
        .input-list {
          margin-bottom: 20px;

          label {
            display: flex;
            flex: 1;
            justify-content: space-around;
            line-height: 70px;

            span {
              width: 160px;
              text-align: center;
            }

            input {
              width: 480px;
              padding: 0 10px;
              border: 1px solid #eee;
              background: #f8f8f8;
              border-radius: 10px;
              outline: none;
              font-size: 36px;
              font-family: Arial;

              &::-webkit-input-placeholder {
                font-family: '微软雅黑';
                font-size: 30px;

              }
            }

            textarea {
              width: 480px;
              height: 180px;
              padding: 10px;
              border: 1px solid #eee;
              background: #f8f8f8;
              border-radius: 10px;
              outline: none;
              resize: none;
              font-size: 36px;
              font-family: Arial;

              &::-webkit-input-placeholder {
                font-family: '微软雅黑';
                font-size: 30px;

              }
            }
          }
        }
      }

      .message-btn {
        text-align: center;
        margin-top: 50px;

        button {
          width: 300px;
          height: 80px;
          text-align: center;
          line-height: 80px;
          border: none;
          outline: none;
          background: #ffbf00;
          border-radius: 50px;
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .contact .contact-wrap .contact-form {
    padding: 40px 30px 70px 30px;
  }
}
</style>
