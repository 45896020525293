<template>
  <div class="footers">
    <div class="footers-banner" v-for="(item, index) in info"
        :key="index">
      <div class="info">
        <div class="list"  >
          <span class="nums">01</span>
          <p class="phone">{{$t('phone')}}</p>
          <p class="content">{{item.phone}}</p>
        </div>
        <div class="list">
          <span class="nums">02</span>
          <p class="phone">{{$t('fax')}}</p>
          <p class="content">{{item.fax}}</p>
        </div>
      </div>
      <div class="copyright">
      {{item.copyright}} <a href="https://beian.miit.gov.cn/">{{item.icp}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'Home',
  components: {},
  apollo: {
    info: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              info: allFooters {
                copyright
                phone
                fax
                icp
              }
            }
          `;
        }

        return gql`
          query {
            info: allEnFooters {
              copyright
              phone
              fax
              icp
            }
          }
        `;
      },
    },
  },
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
.footers-banner {
  width: 100%;
  padding: 0;
  margin: 0;
  background: #070707;
  height: 100%;

  .info {
    display: flex;
    flex: 1;
    justify-content: space-between;
    height: 180px;
    border-bottom: 1px solid #555;

    .list {
      width: 49%;
      text-align: center;
      color: #fff;
      position: relative;
      height: 90px;
      margin: 50px 0;

      &:nth-child(1) {
        width: 50%;
        border-right: 1px solid #666;
      }

      .nums {
        display: inline-block;
        width: 70px;
        height: 70px;
        text-align: center;
        line-height: 80px;
        background: #1a1a1a;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        left: 30px;
        font-size: 30px;
      }

      .phone {
        position: absolute;
        top: 0px;
        left: 120px;
        font-size: 28px;
        color: #fff;
      }

      .content {
        position: absolute;
        top: 50px;
        left: 120px;
        font-size: 28;
      }
    }
  }

  .copyright {
    text-align: center;
    line-height: 80px;
    color: #666;
    a{
       color: #666;
    }

  }
}

@media screen and (max-width: 320px) {
  .footers-banner .info .list .nums {
    left: 10px;
  }
  .footers-banner .info .list .phone,
  .footers-banner .info .list .content {
    left: 90px !important;
  }
}
</style>
